import React, { useEffect } from "react";

import gsap from "gsap";

const CookiesPage = (props) => {
  const { setScroll } = props;

  const tl = gsap.timeline();

  useEffect(() => {
    tl.to("#header", {
      opacity: 1,
      y: 0,
      duration: 0.25,
      delay: 0,
    });

    setScroll(false);
  }, []);

  return (
    <section className="privacypolicy container">
      <h1>Política de cookies</h1>
      <p>
        Adalmáris utiliza cookies en sus sitios web para mejorar el rendimiento
        y la experiencia del usuario como se describe a continuación.
      </p>
      <h2>Conociendo las cookies</h2>
      <p>
        Las cookies son pequeños archivos de texto con información relevante que
        su dispositivo de acceso (computadora, teléfono móvil / teléfono
        inteligente o tableta) se carga, a través del navegador de Internet
        (browser), cuando un sitio web se visitado por el usuario.
      </p>
      <p>
        La colocación de cookies no solo ayudará al sitio web a reconocer el
        dispositivo. Usuario la próxima vez que lo visite, pero también, con
        frecuencia, será fundamental para el funcionamiento de la misma.
      </p>
      <p>
        Las cookies utilizadas por Adalmáris, en todos sus sitios web, no
        recopilan información personal para identificar al usuario. Guardia
        información genérica, a saber, la forma o ubicación / país de acceso de
        los usuarios y cómo utilizan los sitios, entre otros.
      </p>
      <p>
        Las cookies solo retienen información relacionada con sus preferencias.
        El usuario puede, en cualquier momento y a través de su navegador
        Internet (browser), decidir ser notificado sobre el uso de cookies, así
        como bloquear su entrada en su sistema. Cabe señalar, sin embargo, si el
        usuario decidir negarse a utilizar cookies en el sitio web puede
        resultar en imposibilidad de acceder a algunas de sus áreas y no
        permitir todas las experiencia de navegación.
      </p>
      <h2>Funcionalidad de cookies</h2>
      <p>
        En general, las cookies se utilizan para mejorar la experiencia de
        navegación del usuario, aumentando la velocidad y eficiencia de acceso.
        Eliminan la necesidad de ingresar repetidamente la misma información y,
        ayuda a determinar que mensajes publicitarios son relevantes para el
        usuario y se ajustan a sus intereses..
      </p>
      <h2>Diferentes tipos de cookies</h2>
      <p>Cada cookie utilizada tiene una función y una fecha de caducidad.</p>
      <p>En cuanto a la función, las cookies utilizadas pueden ser:</p>
      <ul>
        <li>
          <p>
            <u>Cookies esenciales</u> – Algunas cookies son esenciales para
            acceder a áreas específicas. Permiten navegación en el sitio web y
            el uso de sus aplicaciones, como el acceso a áreas seguras del sitio
            mediante el inicio de sesión. Sin estas cookies, los servicios que
            lo requieren no funcionan.
          </p>
        </li>
        <li>
          <p>
            <u>Cookies de funcionalidad</u> – Las cookies de funcionalidad
            permiten recordar las preferencias del usuario con respecto a la
            navegación en el sitio web. Por lo tanto, no es necesario que lo
            reconfigure y personalice cada vez que lo visite.
          </p>
        </li>

        <li>
          <p>
            <u>Cookies analíticas</u> – Estas cookies se utilizan para analizar
            la forma en que los usuarios utilizan el sitio, permite resaltar
            artículos o servicios que pueden ser de interés para los usuarios, y
            monitorear el rendimiento del sitio. Para descubrir qué páginas son
            las más populares, qué método de vinculación entre páginas que es
            más eficaz o para determinar por qué algunas páginas reciben
            mensajes de error. Estas cookies se utilizan solo con fines de
            creación y análisis estadístico, sin recopilar información personal.
          </p>
        </li>
      </ul>
      <p>
        De esta forma, Adalmáris puede brindar una experiencia de alta calidad
        personalizando su oferta e identificando y corrigiendo rápidamente
        cualquier problema que surja. En cuanto a la fecha de caducidad, las
        cookies utilizadas pueden ser:
      </p>
      <ul>
        <li>
          <p>
            <u>Cookies permanentes</u> – Estan almacenados en sus dispositivos
            de acceso (computadora, teléfono móvil / teléfono inteligente o
            tableta), al nivel del navegador de Internet (browser), y se
            utilizan cada vez que el usuario visita el sitio nuevamente. Por lo
            general, se utilizan para dirigir la navegación según los intereses
            del usuario, lo que permite a Adalmáris brindar un servicio más
            personalizado.
          </p>
        </li>
        <li>
          <p>
            <u>Cookies de sesión</u> – Son temporales y están disponibles hasta
            que finaliza la sesión. La próxima vez que el usuario acceda a su
            navegador de Internet (browser) las cookies ya no se almacenarán. La
            información obtenida le permite administrar las sesiones,
            identificar problemas y brindar una mejor experiencia de navegación.
            El usuario puede desactivar algunas o todas las cookies en cualquier
            momento. Cuando deshabilita las cookies, es posible que el sitio web
            de Adalmáris no funcione correctamente.
          </p>
        </li>
      </ul>
    </section>
  );
};

export default CookiesPage;
